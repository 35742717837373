<template>
    <label class="toggle">
        <input type="checkbox" />
        <span class="toggle__slider"></span>
    </label>
</template>

<script>
import './toggle.scss'

export default {
    name: 'Toggle'
}
</script>