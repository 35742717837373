<template>
    <Page>
        <template v-slot:title>
            Подписки
        </template>
        <div class="subscription">
            <div class="subscription__item">
                <p class="subscription__title">
                    СМС рассылка
                </p>
                <p class="subscription__desc">
                    Автоматическая рассылка наиболее подходящих вам кредитных предложений
                    на указанный при регистрации номер телефона
                </p>
                <div class="subscription__toggle">
                    <Toggle/>
                </div>
            </div>
            <div class="subscription__item">
                <p class="subscription__title">
                    E-mail рассылка
                </p>
                <p class="subscription__desc">
                    Автоматическая рассылка наиболее подходящих вам кредитных предложений
                    на указанный при регистрации адрес электронной почты
                </p>
                <div class="subscription__toggle">
                    <Toggle/>
                </div>
            </div>
            <div class="subscription__item">
                <p class="subscription__title">
                    Защита от непредвиденных ситуаций
                </p>
                <p class="subscription__desc">
                    Включением в список застрахованных лиц.<br/>
                    Стоимость платной услуги 1197 рублей, оплата 3 платежами раз в 5 дней.
                </p>
                <div class="subscription__toggle">
                    <Toggle/>
                </div>
            </div>
            <div class="subscription__action">
                <base-button type="button" mode="yellow">
                    Сохранить изменения
                </base-button>
            </div>
        </div>
    </Page>
</template>

<script>
import './subscription.scss'

import Toggle from '@/components/form/toggle/Toggle'

import Page from '@/applications/personal-area/layouts/page/Page'

export default {
    name: 'Subscription',
    components: {
        Page,
        Toggle
    }
}
</script>